import React from "react"
import { graphql } from "gatsby"

import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import DemoForm from '../Elements/DemoForm';

const HeroSection =  ({ slice }) => {
  const { primary } = slice
  const {
    form_dialog_heading, heading, video_url,
  } = primary
  return (
    <>
      <DemoForm title={form_dialog_heading.text} buttonText="Sign up" />
      <div className="background1 md:py-40 lg:pt-48 pb-20 pt-32 text-center">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="md:max-w-xl mx-auto sm:text-5xl text-3xl font-medium text-blue mb-12 tracking-tighter">{heading && heading.text}</h1>
          <div className="videocontainer shadow-lg rounded-xl">
            <iframe src={video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="btns flex justify-center items-center text-center md:flex-row flex-col gap-4 md:mt-16 mt-10">
            <ButtonGetStarted/>
          </div>
        </div>
      </div>
    </>
  )
};

export default HeroSection

export const query = graphql`
  fragment demoHeroSlice on PrismicDemoDataBodyHero {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      form_dialog_heading {
        text
      }
      video_url
    }
  }
`

