import React from 'react';
import ReactDOM from 'react-dom';

const root =
  typeof document === 'undefined' ? undefined : document.body;

const DialogBase = ({ children, open }) => {
  if (!root) return null;
  const dialog = (
    <dialog
      className="fixed overflow-hidden flex-col top-0 left-0 h-screen w-screen"
      open={open}
      style={{ zIndex: 100 }}
    >
      {children}
    </dialog>
  );
  return ReactDOM.createPortal(dialog, root);
};

export default DialogBase;
