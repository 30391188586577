import * as React from "react"
import { graphql } from "gatsby"
import Testimonial1 from "../Elements/Testimonials1"

const HomeTestimonial = ({ slice }) => {
    const { primary, items } = slice
    const { heading } = primary
    return (
        <div className="bg-gradient xl:pt-32 md:pt-24 pt-16 md:pb-32 pb-24 overflow-hidden teamtestimonial">
            <div className="ml-auto flex lg:flex-row flex-col relative">
                <div className="w-full px-4">
                     <h2 className="xl:w-96 lg:pl-4 lg:ml-auto xl:text-6xl text-5xl text-white font-medium tracking-tighter mb-12">
                       {heading?.text}
                     </h2>
                </div>
                <div className="lg:w-2/3 w-full relative">
                    <Testimonial1 data={items}/>
                </div>
            </div>
        </div>
    )
}

export default HomeTestimonial

export const query = graphql`
  fragment demoTestimonialSlice on PrismicDemoDataBodyTestimonials {
    id
    slice_type
    primary {
    heading {
        text
        html
    }
    }
    items {
    testimonial {
        document {
        ... on PrismicTestimonials {
            id
            data {
            author
            designation
            content
            image {
                      fluid {
                        srcWebp
                        srcSetWebp
                      }
                    }
            }
        }
        }
    }
    }
  }
`
