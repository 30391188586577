import React, { useRef, useState } from 'react';
import axios from 'redaxios';

import { blockEvent, form } from '../../frontend-shared/helpers';
import { FormButton } from '../../frontend-shared/components';

import DialogBase from './DialogBase';
import TextField from './TextField';

const dataKey = 'demo-form';
const emailKey = 'email';

const storage = typeof localStorage === 'undefined' ? {
  get: () => undefined,
  set: () => undefined,
} : {
  get: localStorage.getItem.bind(localStorage),
  set: localStorage.setItem.bind(localStorage),
};

const DemoForm = ({
  title,
  buttonText,
}) => {
  const formRef = useRef(null);
  const submittedAlready = Boolean(storage.get(dataKey));
  const [actionState, setActionState] = useState(
    submittedAlready ? 'disabled' : 'ready',
  );
  const [actionText, setActionText] = useState(buttonText);
  const preSubmit = () => form.submit(formRef);
  const onSubmit = (event) => {
    blockEvent(event);
    const form = formRef.current;
    const url = form.getAttribute('action');
    const method = form.getAttribute('method');
    const formData = new FormData(form);
    const email = formData.get('Email');
    setActionState('waiting');
    return axios[method](url, formData).then(() => {
      storage.set(dataKey, 'true');
      storage.set(emailKey, email);
      setActionState('disabled');
    }).catch(() => {
      setActionState('ready');
      setActionText('Encountered an error. Please try again.');
    });
  };
  return (
    <DialogBase open={actionState !== 'disabled'}>
      <div className="bg-blue-100 bg-opacity-95 grid grid-rows-[0.1fr,1fr,0.1fr] h-full w-full">
        <div className="flex flex-row items-center justify-end w-screen p-5">
          <button
            className="bg-white flex items-center justify-center w-10 h-10 shadow-lg rounded-full hover:bg-zinc-50 focus:bg-zinc-100"
            onClick={() => setActionState('disabled')}
          >
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="24" height="24">
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center justify-center">
          <form
            action="https://crm.zoho.com/crm/WebToLeadForm"
            acceptCharset="utf-8"
            className="formblock bg-white flex flex-col items-center justify-center p-16 rounded-xl"
            onSubmit={onSubmit}
            method="post"
            name="WebToLeads5159490000000767164"
            ref={formRef}
          >
            <h2 className="md:max-w-xl mx-auto sm:text-5xl text-3xl font-medium text-blue mb-12">
              {title}
            </h2>
            <div className="hidden">
              <input type="text" name="xnQsjsdp" value="5415f13a37fdfe95794bf3f265bfae469ae6c2d0068174bb5e27f5db5219a775" />
              <input type="hidden" name="zc_gad" value="" />
              <input type="text" name="xmIwtLD" value="99b909cfe362a59d7be202acdef7be6adc99ea230d203d668c7c5f3ec87242a4" />
              <input type="text" name="actionType" value="TGVhZHM=" />
              <input type="text" name="returnURL" value="https&#x3a;&#x2f;&#x2f;deploy-preview-6--carriyo-gatsby-demo.netlify.app&#x2f;contact&#x2f;"  />
              <select name="Lead Source">
                <option selected value="Website - Demo">
                  Demo
                </option>
              </select>
            </div>
            <TextField
              label="Your email"
              name="Email"
              required
              type="email"
            />
            <button className="hidden" hidden type="submit">
              The real submit button
            </button>
            <FormButton onClick={preSubmit} size="lg" state={actionState} width="full">
              {actionText}
            </FormButton>
          </form>
        </div>
        <div />
      </div>
    </DialogBase>
  );
};

export default DemoForm;
